import { ZoneContextManager } from "@opentelemetry/context-zone";
import { BatchSpanProcessor, AlwaysOnSampler } from "@opentelemetry/sdk-trace-base";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { B3Propagator } from "@opentelemetry/propagator-b3";

import React, { ReactElement } from "react";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { Resource } from "@opentelemetry/resources";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";
import { Attributes, Span, SpanStatusCode, trace } from "@opentelemetry/api";

// only run in browser
if (typeof window !== "undefined") {
  const collectorOptions = {
    url: process.env.GATSBY_TRACING_URL,
    headers: {}, // an optional object containing custom headers to be sent with each request
    concurrencyLimit: 10, // an optional limit on pending requests
  };
  const otlpHttpExporter = new OTLPTraceExporter(collectorOptions);

  const initialUserIds = getUserIds();

  var attributes = {
    [SemanticResourceAttributes.SERVICE_NAME]: process.env.GATSBY_SERVICE_NAME,
    ...initialUserIds,
  };

  const providerConfig = {
    sampler: new AlwaysOnSampler(),
    resource: Resource.default().merge(new Resource(attributes)),
  };
  const provider = new WebTracerProvider(providerConfig);
  provider.addSpanProcessor(new BatchSpanProcessor(otlpHttpExporter));

  provider.register({
    // Changing default contextManager to use ZoneContextManager - supports asynchronous operations - optional
    contextManager: new ZoneContextManager(),
    propagator: new B3Propagator(),
  });

  let instrumentationConfig = {
    "@opentelemetry/instrumentation-document-load": {},
    "@opentelemetry/instrumentation-xml-http-request": {},
    "@opentelemetry/instrumentation-fetch": {},
    "@opentelemetry/instrumentation-user-interaction": {},
  };

  const setUserIds = (span: Span) => {
    span.setAttributes(getUserIds());
  };

  if (!("user.id" in initialUserIds)) {
    instrumentationConfig["@opentelemetry/instrumentation-xml-http-request"] = {
      applyCustomAttributesOnSpan: setUserIds,
    };

    instrumentationConfig["@opentelemetry/instrumentation-fetch"] = {
      applyCustomAttributesOnSpan: setUserIds,
    };
  }

  // Registering instrumentations
  registerInstrumentations({
    instrumentations: [getWebAutoInstrumentations(instrumentationConfig)],
  });
}

type TraceProps = {
  children: ReactElement;
};

function getUserIds(): Record<string, string> {
  let result: Record<string, string> = {};
  if (typeof localStorage !== "undefined") {
    result["user.visitorId"] = getVisitorId();

    const serializedUser: any = localStorage.getItem("userInfo");
    if (serializedUser != null) {
      const user = JSON.parse(serializedUser);
      result["user.id"] = user.id;
      result["user.discord_id"] = user.discord_id;
    }
  }

  return result;
}

function getVisitorId() {
  let visitorId = localStorage.getItem("visitorId");
  if (visitorId === null) {
    visitorId = generateVisitorId();
    localStorage.setItem("visitorId", visitorId);
  }
  return visitorId;
}

export function Tracing({ children }: TraceProps) {
  return <>{children}</>;
}

function generateUUID(): string {
  let d = new Date().getTime(),
    d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x7) | 0x8).toString(16);
  });
}

function generateVisitorId(): string {
  if (crypto && typeof crypto.randomUUID !== "undefined") {
    return crypto.randomUUID();
  }
  return generateUUID();
}

export function withTrace<T>(name: string, fn: () => T, attributes?: Attributes): T {
  const tracer = trace.getTracer("discord-dungeon-ui");
  return tracer.startActiveSpan(name, (span) => {
    if (typeof attributes !== "undefined") {
      span.setAttributes(attributes);
    }
    try {
      return fn();
    } catch (e: unknown) {
      if (e instanceof Error) {
        span.recordException(e);
        span.setStatus({ message: e.message, code: SpanStatusCode.ERROR });
      } else if (typeof e === "string") {
        span.recordException(e);
        span.setStatus({ message: e, code: SpanStatusCode.ERROR });
      }
      throw e;
    } finally {
      span.end();
    }
  });
}
